import { useMemo } from 'react'

import {
  StyledBookingFieldHalf,
  StyledBookingFieldDate,
  StyledBookingFieldDuration,
  StyledBookingFieldTime,
} from './styles'
import { StyledField } from '../styles'
import Input from '../../Field/Input/Input'
import Select from '../../Field/Select/Select'
import Textarea from '../../Field/Textarea/Textarea'
import DatePicker from '../../Field/DatePicker/DatePicker'
import RadioGroup from '../../Field//RadioGroup/RadioGroup'
import { countries } from '../../../constants/countries'
import {
  requiredValidator,
  emailsEqualValidator,
} from '../../../common/validators'
import { makeInitialValues } from '../../../common/utils'

const paymentMethod = [
  {
    id: 'credit-card',
    label: 'Credit Card',
  },
  {
    id: 'cash',
    label: 'Cash',
  },
  {
    id: 'transfer',
    label: 'Transfer',
  },
]

const startDate = new Date()

export const useFormFields = (models) =>
  useMemo(
    () => {
      const fields = [
        {
          wrapper: StyledBookingFieldHalf,
          component: Input,
          id: 'firstName',
          label: 'First Name',
          name: 'firstName',
          placeholder: 'Type your first name',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Input,
          id: 'lastName',
          label: 'Last Name',
          name: 'lastName',
          placeholder: 'Type your last name',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Input,
          id: 'email',
          label: 'E-mail',
          type: 'email',
          name: 'email',
          placeholder: 'Type your e-mail',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Input,
          id: 'confirmEmail',
          label: 'Confirm e-mail',
          type: 'confirmEmail',
          name: 'confirmEmail',
          placeholder: 'Confirm your e-mail',
          validate: emailsEqualValidator,
        },
        {
          wrapper: StyledField,
          component: Input,
          id: 'phone',
          label: 'Phone',
          name: 'phone',
          type: 'phone',
          placeholder:
            'Enter phone number with country code e.g. ( +44 1234 567 890 )',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Select,
          options: models,
          id: 'companion',
          name: 'companion',
          label: 'Companion',
          placeholder: 'Choose Companion',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Select,
          options: models,
          id: 'country',
          name: 'alternativeCompanion',
          label: 'Alternative Companion',
          placeholder: 'Choose Companion',
          validate: requiredValidator,
        },
        {
          wrapper: StyledField,
          component: Input,
          id: 'addressOfHotelOrResidence',
          label: 'Address of hotel or residence',
          name: 'addressOfHotelOrResidence',
          placeholder: 'Street and number',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Input,
          id: 'city',
          label: 'City',
          name: 'city',
          placeholder: 'Type your city',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldHalf,
          component: Select,
          options: countries,
          id: 'country',
          name: 'country',
          label: 'Country',
          placeholder: 'Select your country',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldDate,
          component: DatePicker,
          id: 'date',
          label: 'Date',
          name: 'date',
          initialValue: startDate,
        },
        {
          wrapper: StyledBookingFieldTime,
          component: Input,
          id: 'timeHours',
          name: 'timeHours',
          label: 'Time',
          type: 'number',
          min: '0',
          placeholder: 'HH',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldTime,
          component: Input,
          id: 'timeMinutes',
          name: 'timeMinutes',
          type: 'number',
          min: '0',
          placeholder: 'MM',
          validate: requiredValidator,
        },
        {
          wrapper: StyledBookingFieldDuration,
          component: Input,
          id: 'duration',
          name: 'duration',
          label: 'Duration',
          placeholder: 'Desired duration (minimum 2 hours)',
          validate: requiredValidator,
        },
        {
          wrapper: StyledField,
          component: RadioGroup,
          title: 'Payment Method',
          name: 'payment-method',
          options: paymentMethod,
          radioGroup: true,
          validate: requiredValidator,
        },
        {
          wrapper: StyledField,
          component: Textarea,
          id: 'specialRequests',
          label: 'Special Requests',
          name: 'specialRequests',
          placeholder: 'Your request...',
        },
      ]
      const initialValues = makeInitialValues(fields)

      return { fields, initialValues }
    },
    [models],
  )
