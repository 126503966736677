import React from 'react'
import { graphql } from 'gatsby'
import orderBy from 'lodash/orderBy'
import { Link, Element } from 'react-scroll'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Button from '../components/Buttons/Button/Button'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import { Heading2 } from '../components/Typography/Typography'
import InfoBox from '../components/InfoBox/InfoBox'
import MediaBox from '../components/MediaBox/MediaBox'
import BookingForm from '../components/Forms/BookingForm/BookingForm'
import {
  TRUNCATED_HERO_TYPES,
  TRUNCATED_HERO_BOX_SIDE_TYPES,
} from '../constants/truncatedHeroTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { replaceAtToSpan, generateHero } from '../common/utils'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import HTMLBody from '../components/HTMLBody'

const BookingsPage = ({ data }) => {
  const page = data.wp.page?.bookingAcf
  const seoData = data.wp.page?.seo

  const models = data.wp.models.edges
    
    .map(({ node: { title } }) => ({
      label: title,
      value: title,
    }))

  const sortedModels = orderBy(
    models,
    [(model) => model.label.toLowerCase()],
    'asc',
  )

  const contentBlocks = page.contentBlocks.content

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={[heroColorMobile, '', heroColorDesktop]}
      >
        <TruncatedHero
          heroImages={heroImages}
          gradient={hasBgGradient ? bgGradient : null}
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroBoxSideType={TRUNCATED_HERO_BOX_SIDE_TYPES.left}
          heroSlantType={slantDirection || 'right'}
          opacityImg={0.8}
          long={isHeroHigh}
        >
          <Container mt={['-6rem', '', '22rem']}>
            <InfoBox
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: replaceAtToSpan(page.heroFields.infoboxTitle),
                  }}
                />
              }
              content={
                <HTMLBody opacity="1" html={page.heroFields.infoboxContent} />
              }
              action={
                <Button
                  as={Link}
                  aria-label="Scroll down"
                  to="bookingsForm"
                  href="#bookingsForm"
                  spy
                  smooth
                >
                  Book Now
                </Button>
              }
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
            />
          </Container>
        </TruncatedHero>
      </Container>

      <Element name="bookingsForm">
        <Container bg={BG_TYPES.black} zIndex={100} position="relative">
          <MediaBox
            image={page.formBgImg.imageFile.childImageSharp.fluid}
            info={page.formDesc}
            alt
          >
            <BookingForm models={sortedModels} />
          </MediaBox>
        </Container>
      </Element>

      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
    </Layout>
  )
}

export default BookingsPage

export const query = graphql`
  query {
    wp {
      page(id: "bookings", idType: URI) {
        seo {
          ...seoFragment
        }
        bookingAcf {
          heroFields {
            slantDirection
            infoboxTitle
            infoboxContent
            heroHeight
            boxBackgroundOpacity
            boxBackgroundColor
            bgMobile
            bgGradient
            bgDesktop
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Bookingacf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Bookingacf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          formDesc
          formBgImg {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 960) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      models(first: 50) {
        edges {
          node {
            id
            title
            acf {
              comingSoon
            }
          }
        }
      }
    }
  }
`
