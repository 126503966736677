import styled from 'styled-components'
import { rgba } from 'polished'

import media, { customMedia } from '../../../common/MediaQueries'
import { StyledField as StyledInput } from '../../Field/styles'
import {
  StyledFormHeader,
  StyledField,
  StyledFieldHalf,
  StyledFormSentMsg,
} from '../styles'

const StyledBookingFormWrapper = styled.div`
  position: relative;
  margin-bottom: 2.5rem;

  @media ${media.phone} {
    margin-bottom: 2rem;
  }
`

const StyledBookingFormInner = styled.div`
  margin-bottom: 1.75rem;

  @media ${media.phone} {
    background: ${({ theme }) => theme.colors.darkGrey};
    border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.15)};
    padding: 1.75rem 1.5rem 1.5rem;
  }
`

const StyledBookingFormHeader = styled(StyledFormHeader)`
  @media ${customMedia.maxPhone} {
    text-align: center;
  }
`

const StyledBookingFieldHalf = styled(StyledFieldHalf)`
  @media ${media.phone} {
    width: calc(50% - 0.5rem);
  }
`

const StyledBookingFormAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;

  @media ${media.phone} {
    margin-top: 0;
    justify-content: flex-end;
  }
`

const StyledBookingFieldDate = styled(StyledField)`
  @media ${media.phone} {
    width: calc(50% - 0.5rem);
  }

  @media ${media.tablet} {
    width: calc(33.3% - 0.5rem);
  }
`

const StyledBookingFieldTime = styled(StyledField)`
  ${StyledInput} + ${StyledInput} {
    margin-left: 1rem;
  }

  @media ${media.phone} {
    width: calc(50% - 0.5rem);

    ${StyledInput} + ${StyledInput} {
      margin-left: 0.25rem;
    }    
  }

  @media ${media.tablet} {
    width: calc(12.5% - 0.5rem);
  }
`

const StyledBookingFieldDuration = styled(StyledField)`
  @media ${media.phone} {
    width: calc(50% - 0.5rem);
  }

  @media ${media.tablet} {
    width: calc(42% - 0.5rem);
  }
`

const StyledBookingFormSentMsg = styled(StyledFormSentMsg)``

export {
  StyledBookingFormWrapper,
  StyledBookingFormInner,
  StyledBookingFormHeader,
  StyledBookingFieldHalf,
  StyledBookingFieldDate,
  StyledBookingFieldDuration,
  StyledBookingFieldTime,
  StyledBookingFormAction,
  StyledBookingFormSentMsg,
}
