import React from 'react'
import { Form, Field } from 'react-final-form'

import {
  StyledBookingFormWrapper,
  StyledBookingFormInner,
  StyledBookingFormHeader,
  StyledBookingFormAction,
  StyledBookingFormSentMsg,
} from './styles'
import { StyledForm, StyledFormContent, StyledFormLoader } from '../styles'
import Button from '../../Buttons/Button/Button'
import { Body, Typo3 } from '../../Typography/Typography'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { FIELD_TYPES } from '../../../constants/fieldTypes'
import { useFormFields } from './mock'
import { useOnSubmitHandler } from '../form'

const BookingForm = ({ models }) => {
  const { isLoading, isSent, onSubmit, message } = useOnSubmitHandler(
    'ajax_save_booking_submission',
  )

  const companionOptions = [
    { label: `I don't know yet`, value: `I don't know yet` },
    ...models,
  ]

  const { fields, initialValues } = useFormFields(companionOptions)

  return (
    <StyledBookingFormWrapper isSent={isSent}>
      <Form
        {...{ initialValues }}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <StyledForm onSubmit={handleSubmit} isLoading={isLoading}>
            {isSent ? (
              <StyledBookingFormSentMsg>
                <Body fontWeight="bold" color="success" opacity="1">
                  {message || 'Your message was sent successfully.'}
                </Body>
              </StyledBookingFormSentMsg>
            ) : (
              <>
                <StyledBookingFormInner>
                  <StyledBookingFormHeader>
                    <Typo3>Bookings</Typo3>
                  </StyledBookingFormHeader>

                  <StyledFormContent>
                    {fields.map(
                      ({
                        wrapper: Wrapper,
                        component: Component,
                        id,
                        name,
                        title,
                        label,
                        placeholder,
                        type,
                        options,
                        disabled,
                        radioGroup,
                        initialValue,
                        min,
                        validate,
                      }) => (
                        <Wrapper key={name}>
                          {radioGroup ? (
                            <Component
                              fieldType={FIELD_TYPES.light}
                              {...{ title, options, name }}
                            />
                          ) : (
                            <Field
                              initialValue={initialValue}
                              name={name}
                              validate={!!validate && validate(values)}
                            >
                              {({ input, meta }) => (
                                <Component
                                  fieldType={FIELD_TYPES.light}
                                  id={id}
                                  label={label}
                                  title={title}
                                  placeholder={placeholder}
                                  type={type}
                                  validate={meta.touched && meta.error}
                                  options={options}
                                  disabled={disabled}
                                  name={input.name}
                                  onChange={(value) => input.onChange(value)}
                                  min={min}
                                  {...input}
                                />
                              )}
                            </Field>
                          )}
                        </Wrapper>
                      ),
                    )}
                  </StyledFormContent>
                </StyledBookingFormInner>
                <StyledBookingFormAction>
                  <Button
                    submit
                    type="submit"
                    aria-label="Submit"
                    btnType={BUTTON_TYPES.inverted}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </StyledBookingFormAction>
              </>
            )}
          </StyledForm>
        )}
      />
      {isLoading && <StyledFormLoader />}
    </StyledBookingFormWrapper>
  )
}

export default BookingForm
